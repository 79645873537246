<template>
    <div>
        <div>
        <headerComponent></headerComponent>

        </div>

        <div>
            <div class="contactImage">
                <div class="headerGrid" >
                    <div class="headerGridRow_2" style="margin-top: 64px;"><h1 class="welcome" style="color: #202020;">How can we help you?</h1></div>
                    <div class="headerGridRow_3" ><p class="subtextheader" style="color: #202020;">Please take a moment to explore our FAQ section. If you are unable
                        to find the answer, feel free to use the contact form below.</p></div>
                    </div>

            </div>

        </div>

        <div>
            <faq id="faqAnchor"></faq>
        </div>

        <div>
            <newForm id="contact"></newForm>
        </div>


        <div>
            <mainfooter></mainfooter>
        </div>
    </div>




</template>


<script>

    import request from "@/requests";
    import headerComponent from "@/components/reusable/header";
    import faq from "@/components/reusable/faq";
    import newForm from "@/components/reusable/newForm.vue";

    import mainfooter from "@/components/footer";



    export default {
        name: "ContactPage",
        components: {headerComponent,mainfooter,faq,newForm},

        data: () => ({



            }
           ),

        methods: {




        },


        computed: {

        },

        watch: {

        },



        created() {


            // this.getBlock()
        },
    }


</script>

<style lang="css">

    @import "./../assets/styles/main.css";
    @import "./../assets/styles/mainTablet.css";

    .v-text-field__input{
        color: aliceblue;

    }


    .v-btn__content{
        color: aliceblue;
    }
    .v-main__wrap{
        text-decoration-color: antiquewhite;
        background: #202020;
        color: aliceblue !important;
    }

    .domainNameOnButton{
      position: absolute;
      width: 144px;
      height: 18px;
      left: 50px;
      top: 297px;

      font-family: 'Arial';
      font-style: normal;
      font-weight: 700;
      font-size: 16px;
      line-height: 18px;

      color: #FFFFFF;

    }
    .v-list.v-select-list.v-sheet.theme--light.theme--light{
        background-color: black;
        height: 300px;
    }

    .v-menu__content.theme--light.menuable__content__active{
        max-height: 304px;
        /* min-width: 765px; */
        top: 12px;
        left: 12px;
        transform-origin: left top;
        z-index: 8;
        height: 300px;
    }

    .domainNameOnButton{
        position: absolute;
        width: 144px;
        height: 18px;
        left: 50px;
        top: 297px;

        font-family: 'Arial';
        font-style: normal;
        font-weight: 700;
        font-size: 16px;
        line-height: 18px;

        color: #FFFFFF;

    }
    .pagination {

        height: 32px;
        display: flex;
        justify-content: center;
        margin-top: 32px;
        margin-bottom: 64px;
    }

    .pagination button {
        /*background-color: #eaeaea;*/

        border: none;
        color: #333;
        height: 32px;
        padding: 8px 16px;
        margin: 0 4px;
        cursor: pointer;
        transition: background-color 0.3s ease;
    }

    .pagination button:hover {

        height: 32px;
        background-color: #ccc;
    }

    .pagination button:disabled {

        height: 32px;
        cursor: not-allowed;
        opacity: 0.5;
    }
    .pagination button:enabled {

        height: 32px;
    }

    .pagination button:first-child {

        height: 32px;
        border-top-left-radius: 4px;
        border-bottom-left-radius: 4px;
    }

    .pagination button:last-child {

        height: 32px;
        border-top-right-radius: 4px;
        border-bottom-right-radius: 4px;
    }

    .pagination .page-numbers {

        height: 32px;
        display: flex;
        align-items: center;
    }

    .pagination .page-numbers button {

        height: 32px;
        background-color: transparent;
        border: none;
        color: #333;
        padding: 8px;
        cursor: pointer;
    }

    .pagination .page-numbers button.active {

        height: 32px;
        font-weight: bold;
        text-decoration: underline;
        color: #A5A5A5;
    }

    .v-menu__content {
        position: absolute;
        display: inline-block;
        max-width: 80%;
        overflow-y: auto;
        overflow-x: hidden;
        contain: content;
        -webkit-box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
        box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
        border-radius: 4px;
    }

    div.v-menu__content.theme--light.menuable__content__active{
        overflow: hidden;
        border: thin solid;
        border-radius: 15px;
        border-color: #03DAC6;
        height: 345px;
    }

    div.v-select__selection.v-select__selection--comma {
        color: #03DAC6 !important;
    }





</style>



<!--<template>-->
<!--&lt;!&ndash;    Here goes a form&ndash;&gt;-->
<!--    <div>-->
<!--        Here goes a form-->
<!--&lt;!&ndash;        <DomainInfo></DomainInfo>&ndash;&gt;-->

<!--    </div>-->



<!--</template>-->

<!--<script>-->
<!--    import TutorialDataService from "../services/TutorialDataService";-->
<!--    import request from '../requests';-->
<!--    import requests from "../requests";-->
<!--    import {eventBus} from "@/main";-->
<!--    import DomainInfo from "./reusable/DomainInfo";-->
<!--    export default {-->

<!--        name: "form",-->
<!--        components: {Form},-->
<!--        data() {-->
<!--            return {-->
<!--                valid: true,-->
<!--                tutorial: {-->
<!--                    id: null,-->
<!--                    title: "",-->
<!--                    description: "",-->
<!--                    content: "",-->
<!--                    published: false-->
<!--                },-->
<!--                radioGroup: 1,-->
<!--                radioOptions: [-->
<!--                    {id: 1, description: 'Flat'},-->
<!--                    {id: 2, description: 'House'}-->
<!--                ],-->
<!--                category: 1,-->
<!--                myRules: {-->
<!--                    required: [value => !!value || "Required."]-->
<!--                },-->
<!--                submitted: false-->
<!--            };-->
<!--        },-->
<!--        props: {-->
<!--            editedIndex: Number,-->
<!--            disableFields: Boolean,-->
<!--        },-->

<!--        methods: {-->


<!--            created() {-->
<!--                this.getFK();-->

<!--                this.$router.replace({name: "join", params: {id: "123"}, query: {q1: "q1"}})-->

<!--            }-->

<!--        }-->
<!--    };-->
<!--</script>-->

<!--<style>-->
<!--    .submit-form {-->
<!--        max-width: 300px;-->
<!--        margin: auto;-->
<!--    }-->
<!--</style>-->
