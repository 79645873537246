<template>

    <div id="app">
        <div class="supportHeader"><h2 style="text-align: center">Do you still need our support?</h2></div>
        <div class="supportSubHeader"><p class="supportSubHeaderP" style="text-align: center">If you can’t find your answer to the above <a href="#faqAnchor"><b><u>FAQ</u></b></a> please use the
            following form to describe the issue and we’ll be in touch soon.</p></div>
        <form class="vue-form" @submit.prevent="sendEmail()">

            <div class="error-message">
                <p v-show="!email.valid">Please enter a valid email address.</p>
            </div>

            <fieldset >

                <div>
                    <input type="text" name="name" id="name" required=""
                           v-model="name"
                           placeholder="Full Name">
                </div>
                <div>
                    <input type="email" name="email" id="email" required=""
                           :class="{ email , error: !email.valid }"
                           v-model="email.value"
                           placeholder="Email">
                </div>


                <div>
                    <ul class="vue-form-list">
                        <li v-for="(feature, index) in features">
                            <input type="checkbox"
                                   :value="feature"
                                   :id="'cb-feature-'+index"
                                   :v-model="selection.features"
                                   :style="'background-color: black!important'"
                                   :color="radioColor">
                            <label :for="'cb-feature-'+index" >{{feature}}</label>
                        </li>

<!--                        <li>-->
<!--                            <input type="checkbox" id="checkbox-all" @click="checkAll">-->
<!--                            <label for="checkbox-all">Check All</label>-->
<!--                        </li>-->
                    </ul>
                </div>
                <div>
<!--                    <label class="label" for="textarea">Message</label>-->
                    <textarea class="message" name="textarea" id="textarea" required="" style="color: #808080"
                              v-model="message.text"
                              placeholder="Enter the description and the domain name of your interest"
                              :maxlength="message.maxlength"></textarea>
                    <span class="counter">{{ message.text.length }} / {{ message.maxlength }}</span>
                </div>
                <div class="term"><p class="termsp">Kindly read <a href="/privacy"><u>Monoverse’s Terms of Use.</u></a><br>
                    To continue, you’ll need to accept the Terms of Use by checking the box.</p></div>
                <div>
                    <input type="checkbox" v-model="checkOne">
                    <label>I accept Monoverse’s terms of use</label>
                </div>
                <div>
<!--                    <v-icon icon="md:info"></v-icon>-->
                    <input :disabled="!checkOne || !email.valid || email.value==='' || selection.features==='' || name==='' || !email.valid" type="submit" onclick = "sendEmail()" value="Send Email">
                </div>
            </fieldset>


        </form>

<!--        <div class="debug">-->
<!--            <pre><code>{{ $data }}</code></pre>-->
<!--        </div>-->

        <div>
            <h1 class="landLine">Monoverse operates a land line indeed!</h1>
        </div>
        <div class="landLineBox">
            <div class="landLineBoxElipse">
                <svg xmlns="http://www.w3.org/2000/svg" width="80" height="80" viewBox="0 0 80 80" fill="none">
                    <circle cx="40" cy="40" r="40" fill="#8F8F8F"/>
                </svg>
            </div>
            <div class="landLineBoxParagraph">
                Feel free to give us a call at
            </div>
            <div class="landLineBoxHeader">
                <a href="tel:+442045771070">+44 2045771070</a>
            </div>

        </div>
    </div>
</template>


<script >
    // import { Email } from "@/assets/smtp/smtp.js"
    // import { Email.send }  from "@/assets/smtp/smtp.js"
    import request from "@/requests";

    export default {
        name: "newForm",
        data: function() {
            return {
                name: "",
                checkOne:0,
                radioColor: 'blue',
                email: {
                    value: "",
                    valid: true
                },
                features: ["Interested in Custom Services (branding, hosting, corporate, legal etc)", "Eager to Negotiate Price", "Wish to apply for Domain Parking"],
                selection: {
                    member: "0",
                    framework: "vue",
                    features: []
                },
                message: {
                    text: "",
                    maxlength: 255
                },
                submitted: false
            };

        },

        methods: {

            sendEmail(){
                // console.log(1);


                let obj = {};



                obj.customerEmail = this.email.value;
                obj.body = this.message.text;
                obj.subject = this.selection.features.toString()
                obj.name = this.name;
                // console.log(obj)

                request.postRequest('', 'contact/customer-email', obj,this.postRequestCallback);


            },

            postRequestCallback(response){
                // console.log(response)
                document.location.href="/email-received";
            },

            // submit form handler
            submit: function() {
                this.submitted = true;
            },
            // validate by type and value
            validate: function(type, value) {
                if (type === "email") {
                    this.email.valid = this.isEmail(value) ? true : false;
                }
            },
            // check for valid email adress
            isEmail: function(value) {
                return emailRegExp.test(value);
            },
            // check or uncheck all
            checkAll: function(event) {
                this.selection.features = event.target.checked ? this.features : [];
            }
        },
        watch: {
            // watching nested property
            "email.value": function(value) {
                this.validate("email", value);
            }
        }
    };


    var emailRegExp = /^[a-zA-Z0-9.!#$%&'*+\/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/;


    /* SmtpJS.com - v3.0.0 */
    var Email = {
        send: function (a) {
            return new Promise(function (n, e) {
                a.nocache = Math.floor(1e6 * Math.random() + 1), a.Action = "Send";
                var t = JSON.stringify(a);
                Email.ajaxPost("https://smtpjs.com/v3/smtpjs.aspx?", t, function (e) {
                    n(e)
                })
            })
        }, ajaxPost: function (e, n, t) {
            var a = Email.createCORSRequest("POST", e);
            a.setRequestHeader("Content-type", "application/x-www-form-urlencoded"), a.onload = function () {
                var e = a.responseText;
                null != t && t(e)
            }, a.send(n)
        }, ajax: function (e, n) {
            var t = Email.createCORSRequest("GET", e);
            t.onload = function () {
                var e = t.responseText;
                null != n && n(e)
            }, t.send()
        }, createCORSRequest: function (e, n) {
            var t = new XMLHttpRequest;
            return "withCredentials" in t ? t.open(e, n, !0) : "undefined" != typeof XDomainRequest ? (t = new XDomainRequest).open(e, n) : t = null, t
        }
    };


</script>

<style>


</style>
